
html,
body {
  position: relative;
  box-sizing: border-box;
  text-rendering: optimizeSpeed;
  //font-family: 'Familjen Grotesk', sans-serif;
  //font-family: 'Poppins', sans-serif;
  //font-family: 'Inter', sans-serif;
  font-display: optional;
  padding: 0;
  margin: 0;
  //background: #070f1b;
  //background: #08080e;
  background: #0a0a0a;
  color:#f1f1f1;
  overflow: overlay;
  font-style: normal;
  min-height: 100%;

  ::-webkit-scrollbar {
    height: 4px;
    width: 0px;
    background: #00000000;
  }

}

#globalLoader {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  background-color:  #08080e;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contentBasic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background-image: linear-gradient(rgb(14, 16, 22), rgb(10,10,10));
  //background-image: linear-gradient(rgb(6, 5, 8), rgb(10,10,10));

  background-size: 100% 85%;
  background-repeat: no-repeat;
}

a, a:link, a:visited, a:hover, a:active {
  color: inherit;
  border: none;
  font-size: inherit !important;
  text-decoration: none !important;
  outline: none;
  text-align: inherit !important;
  width: inherit;
}